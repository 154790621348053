<template>
  <g-loading :loading="true" />
</template>

<script>
import { path_includes } from '../../router'
import { login, medical_cards, token_login } from "./service.js";
import { verifyArr } from "./config.js";
import { isWechat } from "../../utils/validate";
import { setSession, getSession } from "../../utils/session";


export default {
  data() {
    return {};
  },
  computed: {},
  components: {},
  created() {
    this.submit_form();
  },
  methods: {
    async submit_form() {
      let {code, token, redirect} = this.$route.query

      // 微信端
      if (isWechat && !code && !token) {
        // 是否需要跳转
        let redirect_url = encodeURIComponent(location.href)
        let str = `?redirectUrl=${redirect_url}`
        if (GLOBAL_CONFIG.luohu_url.login.includes('?')) {
          str = `&redirectUrl=${redirect_url}`
        }
        location.href = GLOBAL_CONFIG.luohu_url.login + str
        throw "暂停执行"; // 退出后面的执行
      }

      // 登录
      let data = null
      if (code) {
        let res_data = await login({ code });
        data = res_data.data
      } else if (token) {
        let res_data = await token_login({ code: token});
        data = res_data.data
      } else {
        // 电脑端模拟登录
        let res_data = await login({ code: '259659AE96C2428987C081A4ED7932C0' });
        data = res_data.data
      }
      data.verifyArr = verifyArr;
      setSession(data);

      // 是否有健康卡
      const auth = getSession();
      const { data: _data } = await medical_cards();
      if (!_data || !_data.name) {
        auth.has_card = false;
      } else {
        auth.has_card = true;
      }
      setSession(auth);

      // 是否该页面有访问权限
      redirect = decodeURIComponent(redirect)
      let path = redirect
      if (redirect && redirect.includes('?')) {
        path = redirect.split('?')[0]
      }
      if (path_includes(path, verifyArr)) {
        this.$router.replace({ path: redirect })
      } else {
        this.$router.replace({ path: '/' })
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
